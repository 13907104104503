import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Loader } from "components/common/Loader";
import { useMount } from "hooks/useMount";
import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import * as newsActions from "modules/news/actions";
import { FC, ReactNode, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";
import * as s from "./styles";
import { NewsFeedProps } from "./types";

const NewsFeedPostContentLink = (props: {
  title: string;
  rel: string;
  target: string;
  href: string;
  children: ReactNode;
}) =>
  props.href?.startsWith("#") ? (
    <Link {...props} />
  ) : (
    <Link {...props} rel={"noopener noreferrer"} target={"_blank"} />
  );

const SCROLLABLE_TARGET_ID = "postsContainer";

const markdownOptions: MarkdownToJSX.Options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    h2: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    h3: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    h4: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    h5: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    h6: {
      component: Typography,
      props: {
        component: "p"
      }
    },
    p: {
      component: Typography
    },
    a: NewsFeedPostContentLink,
    hr: Divider,
    img: s.NewsFeedPostContentImage,
    pre: s.NewsFeedPostContentPreformattedText
  },
  wrapper: "div",
  forceWrapper: true
};

export const NewsFeed: FC<NewsFeedProps> = ({
  posts,
  onClose,
  onLoadMore,
  hasMore
}: NewsFeedProps) => {
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(newsActions.markNewsFeedPostsAsRead.started());
  });

  const handleCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleLoadMore = () => {
    onLoadMore();
  };

  return (
    <s.Container>
      <s.TitleContainer>
        <Typography variant={"h5"} component={"h2"}>
          News
        </Typography>
        <Tooltip title={"Close"}>
          <span>
            <IconButton
              onClick={handleCloseButtonClick}
              color={"inherit"}
              title={"Close"}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </s.TitleContainer>
      <Divider />
      <s.PostsContainer id={SCROLLABLE_TARGET_ID}>
        <InfiniteScroll
          dataLength={posts.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={
            <s.LoaderContainer>
              <Loader text={"Loading..."} />
            </s.LoaderContainer>
          }
          scrollableTarget={SCROLLABLE_TARGET_ID}
        >
          {posts.map((post) => (
            <s.NewsFeedPost key={post.id} variant={"outlined"}>
              <Typography variant={"caption"}>
                {formatDate(new Date(post.created_at), DATE_FORMATS.DATE)}
              </Typography>
              <s.NewsFeedPostTitle variant={"h6"} component={"h3"}>
                {post.title}
              </s.NewsFeedPostTitle>
              <Markdown options={markdownOptions}>{post.content}</Markdown>
            </s.NewsFeedPost>
          ))}
        </InfiniteScroll>
      </s.PostsContainer>
    </s.Container>
  );
};
