import { Volume } from "modules/storage/types";
import { LINUX_DISTROS, OS_PLATFORMS } from "../../constants";
import { PROTOCOLS } from "../../types";

export enum INSTANCE_STATUSES {
  ACTIVE = "ACTIVE",
  BUILD = "BUILD",
  DELETED = "DELETED",
  ERROR = "ERROR",
  HARD_REBOOT = "HARD_REBOOT",
  MIGRATING = "MIGRATING",
  PASSWORD = "PASSWORD",
  PAUSED = "PAUSED",
  REBOOT = "REBOOT",
  REBUILD = "REBUILD",
  RESCUE = "RESCUE",
  RESIZE = "RESIZE",
  REVERT_RESIZE = "REVERT_RESIZE",
  SHELVED = "SHELVED",
  SHELVED_OFFLOADED = "SHELVED_OFFLOADED",
  SHUTOFF = "SHUTOFF",
  SOFT_DELETED = "SOFT_DELETED",
  SUSPENDED = "SUSPENDED",
  UNKNOWN = "UNKNOWN",
  VERIFY_RESIZE = "VERIFY_RESIZE"
}

export enum SOURCE_TYPES {
  VOLUME = "volume",
  SNAPSHOT = "snapshot",
  IMAGE = "image"
}

export type InstanceFault = {
  code: number;
  created: string;
  message: string;
  details?: string;
};

export type Instance = {
  created: string;
  flavor_id: string;
  id: string;
  image_id: string;
  key_name: string;
  name: string;
  networks: Record<
    string,
    {
      "OS-EXT-IPS-MAC:mac_addr": string;
      "OS-EXT-IPS:type": string;
      addr: string;
      version: number;
    }[]
  >;
  region: string;
  attached_volumes: {
    id: string;
  }[];
  firewalls: {
    name: string;
  }[];
  status: INSTANCE_STATUSES;
  fault: InstanceFault;
  updated: string;
  metadata: Record<string, string>;
  tags: string[];
};

export type TableVirtualMachine = {
  id: string;
  name: string;
  status: INSTANCE_STATUSES;
  ip: string;
  keypair: string;
  vCPUs: number;
  memory: string;
  flavorId: string;
  imageId: string;
  created: string;
  tags: string[];
  tagsString: string;
};

export type GetInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
  pollingId?: string;
};

export type GetInstanceResponse = Instance;

export type GetInstancesParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetInstancesResponse = Instance[];

export type CreateInstanceParams = {
  regionId: string;
  projectId: string;
  data: {
    block_device_mapping_v2?: {
      delete_on_termination?: boolean;
      volume_size: number;
    };
    flavorRef: string;
    source_type: string;
    linux_distros: string;
    sourceRef: string;
    key_name?: string;
    name: string;
    networks: {
      uuid: string;
    }[];
    firewalls?: string[];
    password?: string;
    tags: string[];
    count: number;
  };
};

export type CreateInstanceResponse = Instance;

export type UpdateInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
  data: {
    name: string;
    tags?: string[];
  };
};

export type UpdateInstanceResponse = Instance;

export type ResizeInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
  flavorId: string;
};

export type ResizeInstanceResponse = { message: string };

export type ConfirmResizeInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type ConfirmResizeInstanceResponse = Instance;

export type RevertResizeInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type RevertResizeInstanceResponse = Instance;

export type DeleteInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type DeleteInstanceResponse = { message: string };

export type Flavor = {
  "OS-FLV-EXT-DATA:ephemeral": number;
  disk: number;
  id: string;
  name: string;
  "os-flavor-access:is_public": boolean;
  ram: number;
  region: string;
  swap: number;
  vcpus: number;
};

export type SelectOptionFlavor = {
  id: string;
  name: string;
};

export type GetFlavorParams = {
  regionId: string;
  projectId: string;
  id: string;
  pollingId?: string;
};

export type GetFlavorResponse = Flavor;

export type GetFlavorsParams = {
  regionId: string;
  projectId: string;
  type: string;
  pollingId?: string;
};

export enum FLAVOR_TYPE {
  INSTANCE = "instance",
  CLUSTER = "cluster"
}

export type GetFlavorsResponse = Flavor[];

export type StartInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export enum RESTART_TYPE {
  SOFT = "soft",
  HARD = "hard"
}

export type RestartInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
  type: RESTART_TYPE;
};

export type StopInstanceParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type Image = {
  checksum: string;
  created_at: string;
  disk_format: string;
  id: string;
  name: string;
  region: string;
  size: number;
  status: string;
  tags: string[];
  updated_at: string;
  os_platform: OS_PLATFORMS | "";
  os_distro: LINUX_DISTROS | string;
  os_version: string;
  os_default_user?: string;
  deprecated: boolean;
};

export type ImageInfo = {
  disk_format: string;
  size: string | number;
  image_id: string;
  image_name: string;
  os_distro: string;
  os_platform: string;
  os_default_user?: string;
};

export type TableImage = {
  id: string;
  name: string;
  status: string;
  size: string;
  createdAt: string;
  osPlatform: OS_PLATFORMS | "";
  osDistro: LINUX_DISTROS | string;
  osVersion: string;
  osDefaultUser?: string;
  isDeprecated: boolean;
};

export type GetImageParams = {
  regionId: string;
  projectId: string;
  id: string;
  pollingId?: string;
};

export type GetImageResponse = Image;

export type GetImagesParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetImagesResponse = Image[];

export type Interface = {
  fixed_ips: {
    ip_address: string;
    subnet_id: string;
  }[];
  mac_address: string;
  network_id: string;
  security_groups: string[];
  id: string;
  status: string;
  admin_state_up: boolean;
  device_id: string;
};

export type TableInterface = {
  status: string;
  ip: string;
  networkName: string | undefined;
  subnetId: string;
  firewalls: string;
  id: string;
  name: string;
  macAddr: string;
  networkId: string;
};

export type GetInterfaceParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  id: string;
  pollingId?: string;
};

export type GetInterfaceResponse = Interface;

export type GetInterfacesParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  pollingId?: string;
};

export type GetInterfacesResponse = Interface[];

export type CreateInterfaceParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  data: {
    fixed_ips?: {
      ip_address: string;
    }[];
    network_id: string;
  };
};

export type CreateInterfaceResponse = Interface;

export type DeleteInterfaceParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  id: string;
};

export type DeleteInterfaceResponse = { message: string };

export type InstanceLimits = {
  absolute: {
    maxImageMeta: number;
    maxPersonality: number;
    maxPersonalitySize: number;
    maxSecurityGroupRules: number;
    maxSecurityGroups: number;
    maxServerGroupMembers: number;
    maxServerGroups: number;
    maxServerMeta: number;
    maxTotalCores: number;
    maxTotalFloatingIps: number;
    maxTotalInstances: number;
    maxTotalKeypairs: number;
    maxTotalRAMSize: number;
    totalCoresUsed: number;
    totalFloatingIpsUsed: number;
    totalInstancesUsed: number;
    totalRAMUsed: number;
    totalSecurityGroupsUsed: number;
    totalServerGroupsUsed: number;
  };
  region: string;
};

export type GetInstanceLimitsParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetInstanceLimitsResponse = InstanceLimits[];

export type AttachVolumeParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  volumeId: string;
};

export type AttachVolumeResponse = Volume;

export type DetachVolumeParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  volumeId: string;
};

export type DetachVolumeResponse = {
  message: string;
};

export type AddFirewallParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  firewallId: string;
};

export type AddFirewallResponse = Instance;

export type RemoveFirewallParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  firewallId: string;
};

export type RemoveFirewallResponse = Instance;

export type InstanceFirewall = {
  id: string;
  name: string;
  description: string;
  firewall_rules: {
    id: string;
    port_range_min: number;
    port_range_max: number;
    protocol: PROTOCOLS;
    ip_range: {
      CIDR: string;
    };
    firewall_id: string;
  }[];
  tenant_id: string;
  region: string;
};

export type GetInstanceFirewallsParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  pollingId?: string;
};

export type GetInstanceFirewallsResponse = InstanceFirewall[];

export type TableInstanceFirewall = {
  name: string;
  id: string;
  description: string;
};

export type RemoteConsoleURL = {
  url: string;
};

export type GetRemoteConsoleURLParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
};

export type GetRemoteConsoleURLResponse = RemoteConsoleURL;

export type InstanceLog = {
  output: string;
};

export type GetInstanceLogParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  logLength?: number;
};

export type GetInstanceLogResponse = InstanceLog;

export type GetInstanceMetricParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
};

export interface Metric {
  value: number;
  time_stamp: number;
}

export interface InstanceMetrics {
  cpu_metrics: Metric[];
  memory_metrics: Metric[];
}

export type GetInstanceMetricResponse = InstanceMetrics;

export type InstanceActionLogs = {
  request_id: string;
  start_time: string;
  action: string;
  user_id: string;
  email: string;
  instance_id: string;
  region_id: string;
};

export type GetInstanceActionLogsParams = {
  regionId: string;
  projectId: string;
  instanceId: string;
  pollingId?: string;
};

export type GetInstanceActionLogsResponse = InstanceActionLogs[];

export type TableInstanceActionLogs = {
  id: string;
  start_time: string;
  action: string;
  user_id: string;
  email: string;
};
