import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { AccessSecretDialog } from "components/common/AccessSecretDialog";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableMultiActionsItem,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import {
  instancesSelector,
  isVolumeAttachingSelector,
  isVolumeDetachingSelector,
  tableImagesSelector
} from "modules/instances/selectors";
import { TableImage } from "modules/instances/types";
import * as objectStorageActions from "modules/objectStorage/actions";
import {
  areCredentialsCreatingSelector,
  areCredentialsDeletingSelector,
  areCredentialsRegeneratingSelector,
  credentialsSelector,
  tableCredentialsListSelector
} from "modules/objectStorage/selectors";
import { TableCredentials } from "modules/objectStorage/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import * as storageActions from "modules/storage/actions";
import {
  areVolumesDeletingSelector,
  isSnapshotCreatingSelector,
  isSnapshotDeletingSelector,
  isSnapshotUpdatingSelector,
  isVolumeCreatingSelector,
  isVolumeDeletingSelector,
  isVolumeExtendingSelector,
  isVolumeUpdatingSelector,
  tableSnapshotsSelector,
  tableVolumesSelector,
  volumeTypesSelector
} from "modules/storage/selectors";
import {
  TableSnapshot,
  TableVolume,
  VOLUME_STATUSES
} from "modules/storage/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isString } from "typeGuards/isString";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  MIN_VOLUME_SIZE,
  ROUTES,
  VOLUME_SIZE_STEP
} from "../../constants";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "STORAGE";

const POLL_IDS = {
  volumes: "VOLUMES",
  volumeTypes: "VOLUME_TYPES",
  instances: "INSTANCES",
  snapshots: "SNAPSHOTS",
  images: "IMAGES",
  credentialsList: "CREDENTIALS_LIST"
};

const volumeTableColumns: TableColumn<TableVolume>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "status", label: "Status" },
  { key: "sizeString", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  {
    key: "createdAt",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const snapshotTableColumns: TableColumn<TableSnapshot>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "status", label: "Status" },
  { key: "size", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  { key: "description", label: "Description" },
  {
    key: "createdAt",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const imageTableColumns: TableColumn<TableImage>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "status", label: "Status" },
  { key: "size", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  {
    key: "createdAt",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const credentialsTableColumns: TableColumn<TableCredentials>[] = [
  { key: "accessKey", label: "Access key" },
  { key: "endpoint", label: "Endpoint" }
];

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.VOLUMES]: "Volumes",
  [TABS.SNAPSHOTS]: "Snapshots",
  [TABS.IMAGES]: "Images",
  [TABS.OBJECT_STORAGE]: "S3 browser"
};

const title = "Storage";

export const Storage: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const volumes = useSelector(tableVolumesSelector);
  const snapshots = useSelector(tableSnapshotsSelector);
  const credentials = useSelector(credentialsSelector);
  const previousCredentials = usePrevious(credentials);
  const credentialsList = useSelector(tableCredentialsListSelector);
  const volumeTypes = useSelector(volumeTypesSelector);
  const images = useSelector(tableImagesSelector);
  const virtualMachines = useSelector(instancesSelector);
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.VOLUMES) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_VOLUME, isOpened: false });
  const [isAccessSecretDialogOpened, setIsAccessSecretDialogOpened] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const isVolumeCreating = useSelector(isVolumeCreatingSelector);
  const isVolumeUpdating = useSelector(isVolumeUpdatingSelector);
  const isVolumeExtending = useSelector(isVolumeExtendingSelector);
  const isVolumeDeleting = useSelector(isVolumeDeletingSelector);
  const prevIsVolumeDeleting = usePrevious(isVolumeDeleting);
  const areVolumesDeleting = useSelector(areVolumesDeletingSelector);
  const prevAreVolumesDeleting = usePrevious(areVolumesDeleting);
  const isVolumeAttaching = useSelector(isVolumeAttachingSelector);
  const isVolumeDetaching = useSelector(isVolumeDetachingSelector);
  const isVolumeOperationInProgress =
    isVolumeCreating ||
    isVolumeUpdating ||
    isVolumeExtending ||
    isVolumeDeleting ||
    isVolumeAttaching ||
    areVolumesDeleting ||
    isVolumeDetaching;
  const previousIsVolumeOperationInProgress = usePrevious(
    isVolumeOperationInProgress
  );
  const isSnapshotCreating = useSelector(isSnapshotCreatingSelector);
  const isSnapshotUpdating = useSelector(isSnapshotUpdatingSelector);
  const isSnapshotDeleting = useSelector(isSnapshotDeletingSelector);
  const isSnapshotOperationInProgress =
    isSnapshotCreating || isSnapshotUpdating || isSnapshotDeleting;
  const previousIsSnapshotOperationInProgress = usePrevious(
    isSnapshotOperationInProgress
  );
  const areCredentialsCreating = useSelector(areCredentialsCreatingSelector);
  const areCredentialsRegenerating = useSelector(
    areCredentialsRegeneratingSelector
  );
  const areCredentialsDeleting = useSelector(areCredentialsDeletingSelector);
  const isCredentialsOperationInProgress =
    areCredentialsCreating ||
    areCredentialsRegenerating ||
    areCredentialsDeleting;
  const previousIsCredentialsOperationInProgress = usePrevious(
    isCredentialsOperationInProgress
  );

  // to add name to the delete confirmation window
  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingVolumeName = volumes?.find(
    (volume) => volume.id === deletingItemId
  )?.name;
  const deletingSnapshotName = snapshots?.find(
    (snapshot) => snapshot.id === deletingItemId
  )?.name;
  const [resetVolumeCheckboxes, setResetVolumeCheckboxes] = useState(false);
  const deletingVolumesNames = volumes
    ?.filter((volume) => checkedRows?.includes(volume.id))
    .map((volume) => volume.name);

  const handleSwitchOffResetCheckboxes = useCallback(
    () => setResetVolumeCheckboxes(false),
    []
  );

  const handleCreateVolumeButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_VOLUME,
      isOpened: true
    });
  }, []);

  const handleCreateSnapshotButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const handleCreateCredentialsButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_CREDENTIALS,
      isOpened: true
    });
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleAttachVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ATTACH_VOLUME,
      isOpened: true
    });
  }, []);

  const handleDetachVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DETACH_VOLUME,
      isOpened: true
    });
  }, []);

  const handleEditVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_VOLUME,
      isOpened: true
    });
  }, []);

  const handleExtendVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EXTEND_VOLUME,
      isOpened: true
    });
  }, []);

  const handleDeleteVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_VOLUME,
      isOpened: true
    });
  }, []);

  const handleMultiDeleteVolumeMenuItemClick = useCallback((ids: string[]) => {
    setCheckedRows(ids);
    setDialog({
      type: DIALOG_TYPES.DELETE_VOLUMES,
      isOpened: true
    });
  }, []);

  const handleCreateVolumeFromSnapshotMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.CREATE_VOLUME_FROM_SNAPSHOT,
        isOpened: true
      });
    },
    []
  );

  const handleEditSnapshotMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const handleDeleteSnapshotMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const handleShowCredentialsMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.SHOW_CREDENTIALS,
      isOpened: true
    });
  }, []);

  const handleRegenerateCredentialsMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REGENERATE_CREDENTIALS,
      isOpened: true
    });
  }, []);

  const handleDeleteCredentialsMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_CREDENTIALS,
      isOpened: true
    });
  }, []);

  const handleBrowseBucketsButtonClick = useCallback(() => {
    history(
      generatePath(ROUTES.S3_BUCKETS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        credsId: credentialsList ? credentialsList[0].id : ""
      })
    );
  }, [
    history,
    matchParams.organizationId,
    matchParams.regionId,
    matchParams.projectId,
    credentialsList
  ]);

  const volumeTableActions: TableRowActionsMenuItem<TableVolume>[] = [
    {
      label: "Attach",
      handler: handleAttachVolumeMenuItemClick,
      isDisabled: (volume) =>
        volume.status !== VOLUME_STATUSES.AVAILABLE || !virtualMachines
    },
    {
      label: "Detach",
      handler: handleDetachVolumeMenuItemClick,
      isDisabled: (volume) => volume.status !== VOLUME_STATUSES.IN_USE
    },
    {
      label: "Edit",
      handler: handleEditVolumeMenuItemClick
    },
    {
      label: "Extend",
      handler: handleExtendVolumeMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteVolumeMenuItemClick
    }
  ];

  const volumeTableMultiActions: TableMultiActionsItem<TableVolume>[] = [
    {
      label: "Delete",
      handler: handleMultiDeleteVolumeMenuItemClick,
      icon: <DeleteIcon />
    }
  ];

  const snapshotTableActions: TableRowActionsMenuItem<TableSnapshot>[] = [
    {
      label: "Create volume",
      handler: handleCreateVolumeFromSnapshotMenuItemClick
    },
    {
      label: "Edit",
      handler: handleEditSnapshotMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteSnapshotMenuItemClick
    }
  ];

  const credentialsTableActions: TableRowActionsMenuItem<TableCredentials>[] = [
    {
      label: "Show access secret",
      handler: handleShowCredentialsMenuItemClick
    },
    {
      label: "Re-generate secret",
      handler: handleRegenerateCredentialsMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteCredentialsMenuItemClick
    }
  ];

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Storage",
      url: generatePath(ROUTES.STORAGE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(storageActions.clear());
    dispatch(projectsActions.clear());
    dispatch(instancesActions.clear());
    dispatch(objectStorageActions.clear());
  });

  useEffect(() => {
    if (previousIsVolumeOperationInProgress && !isVolumeOperationInProgress) {
      dispatch(
        storageActions.getVolumes.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsVolumeOperationInProgress,
    isVolumeOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (
      previousIsSnapshotOperationInProgress &&
      !isSnapshotOperationInProgress
    ) {
      dispatch(
        storageActions.getSnapshots.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsSnapshotOperationInProgress,
    isSnapshotOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (
      previousIsCredentialsOperationInProgress &&
      !isCredentialsOperationInProgress
    ) {
      dispatch(
        objectStorageActions.getCredentialsList.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsSnapshotOperationInProgress,
    isSnapshotOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsCredentialsOperationInProgress,
    isCredentialsOperationInProgress
  ]);

  useEffect(() => {
    if (previousCredentials !== credentials && credentials) {
      setIsAccessSecretDialogOpened(true);
    }
  }, [previousCredentials, credentials]);

  useEffect(() => {
    if (
      (!areVolumesDeleting && prevAreVolumesDeleting) ||
      (!isVolumeDeleting && prevIsVolumeDeleting)
    ) {
      setCheckedRows([]);
      setResetVolumeCheckboxes(true);
    }
  }, [
    areVolumesDeleting,
    isVolumeDeleting,
    prevAreVolumesDeleting,
    prevIsVolumeDeleting
  ]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.VOLUMES:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.VOLUMES]
          })
        });
        dispatch(storageActions.clear());
        dispatch(instancesActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.volumes}`,
            action: storageActions.getVolumes.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.volumeTypes}`,
            action: storageActions.getVolumeTypes.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.instances}`,
            action: instancesActions.getInstances.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.SNAPSHOTS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.SNAPSHOTS]
          })
        });
        dispatch(storageActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.snapshots}`,
            action: storageActions.getSnapshots.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.volumes}`,
            action: storageActions.getVolumes.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.IMAGES:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.IMAGES]
          })
        });
        dispatch(instancesActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.images}`,
            action: instancesActions.getImages.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.OBJECT_STORAGE:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.OBJECT_STORAGE]
          })
        });
        dispatch(objectStorageActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.credentialsList}`,
            action: objectStorageActions.getCredentialsList.started({
              regionId: matchParams.regionId!,
              projId: matchParams.projectId!
            })
          })
        );
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const tabs = (
    <>
      <Tabs value={activeTabIndex} onChange={handleChangeTab}>
        {Object.values(TAB_TITLES).map((tabTitle) => (
          <Tab key={tabTitle} label={tabTitle} />
        ))}
      </Tabs>
    </>
  );

  const tabContent = [
    <Table<TableVolume>
      title={title}
      key={"volumeTable"}
      rows={volumes || []}
      isSearchEnabled={true}
      isSortingEnabled={true}
      columns={volumeTableColumns}
      actions={volumeTableActions}
      isSelectingEnabled={true}
      multiActions={volumeTableMultiActions}
      toolbarItems={
        <Button
          onClick={handleCreateVolumeButtonClick}
          variant={"contained"}
          disabled={!volumeTypes}
        >
          Create volume
        </Button>
      }
      tabs={tabs}
      isLoading={!volumes}
      resetCheckboxes={resetVolumeCheckboxes}
      onResetCheckboxes={handleSwitchOffResetCheckboxes}
    />,
    <Table<TableSnapshot>
      title={title}
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"snapshotTable"}
      rows={snapshots || []}
      columns={snapshotTableColumns}
      actions={snapshotTableActions}
      toolbarItems={
        <Button
          onClick={handleCreateSnapshotButtonClick}
          variant={"contained"}
          disabled={!volumes}
        >
          Create snapshot
        </Button>
      }
      tabs={tabs}
      isLoading={!snapshots}
    />,
    <Table<TableImage>
      title={title}
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"imageTable"}
      rows={images?.filter((image) => !image.isDeprecated) || []}
      disabledRows={images?.filter((image) => image.isDeprecated) || []}
      columns={imageTableColumns}
      tabs={tabs}
      isLoading={!images}
    />,
    <Table<TableCredentials>
      title={title}
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"storageTable"}
      rows={credentialsList || []}
      columns={credentialsTableColumns}
      actions={credentialsTableActions}
      toolbarItems={
        credentialsList ? (
          credentialsList?.length === 0 ? (
            <Button
              style={{ marginRight: 10 }}
              onClick={handleCreateCredentialsButtonClick}
              variant={"contained"}
            >
              Create s3 credentials
            </Button>
          ) : (
            <Button
              onClick={handleBrowseBucketsButtonClick}
              variant={"contained"}
              disabled={!credentialsList || credentialsList?.length === 0}
            >
              Browse buckets
            </Button>
          )
        ) : (
          ""
        )
      }
      tabs={tabs}
      isLoading={!credentialsList}
    />
  ];

  const handleConfirmCreateVolume = useCallback(
    (data: {
      name: string;
      description: string;
      volume_type: SelectOption;
      size: string;
    }) => {
      dispatch(
        storageActions.createVolume.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim(),
            volume_type: data.volume_type.value,
            size: Number(data.size)
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmCreateVolumeFromSnapshot = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.createVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            data: {
              ...data,
              description: data.description.trim(),
              snapshot_id: selectedItemId,
              size: Number(
                snapshots?.find((snapshot) => snapshot.id === selectedItemId)
                  ?.size
              )
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      snapshots
    ]
  );

  const handleConfirmAttachVolume = useCallback(
    (data: { virtualMachine: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          instancesActions.attachVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            instanceId: data.virtualMachine.value,
            volumeId: selectedItemId
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDetachVolume = useCallback(() => {
    if (selectedItemId) {
      const instanceId = volumes?.find(
        (volume) => volume.id === selectedItemId
      )?.serverId;
      if (instanceId) {
        dispatch(
          instancesActions.detachVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            instanceId,
            volumeId: selectedItemId
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    volumes,
    selectedItemId,
    dispatch,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmEditVolume = useCallback(
    (data: { name: string; description: string; bootable: boolean }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.updateVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmExtendVolume = useCallback(
    (data: { size: string }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.extendVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              new_size: Number(data.size)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteVolume = useCallback(() => {
    let id = "";
    if (selectedItemId) {
      id = selectedItemId;
    } else if (checkedRows.length > 0) {
      id = checkedRows[0];
    }
    if (id) {
      dispatch(
        storageActions.deleteVolume.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    checkedRows,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const handleConfirmDeleteVolumes = useCallback(() => {
    if (checkedRows.length > 0) {
      dispatch(
        storageActions.deleteVolumes.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: checkedRows
        })
      );
    }
    handleCloseDialog();
  }, [
    checkedRows,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const handleConfirmCreateSnapshot = useCallback(
    (data: { name: string; description: string; volume: SelectOption }) => {
      dispatch(
        storageActions.createSnapshot.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim(),
            volume_id: data.volume.value
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditSnapshot = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.updateSnapshot.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteSnapshot = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        storageActions.deleteSnapshot.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmShowCredentials = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        objectStorageActions.getCredentials.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmCreateCredentials = useCallback(() => {
    dispatch(
      objectStorageActions.createCredentials.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmReGenerateCredentials = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        objectStorageActions.regenerateCredentials.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmDeleteCredentials = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        objectStorageActions.deleteCredentials.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_VOLUME]: {
      onConfirm: handleConfirmCreateVolume,
      title: "Create volume",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "volume_type",
          type: FIELD_TYPES.SELECT,
          label: "Volume type",
          options: volumeTypes?.map((volumeType) =>
            getSelectOption(volumeType, "name", "id")
          ),
          rules: selectOptionSchema
        },
        {
          name: "size",
          type: FIELD_TYPES.NUMBER,
          defaultValue: MIN_VOLUME_SIZE,
          label: "Volume size",
          suffix: "GiB",
          min: MIN_VOLUME_SIZE,
          step: VOLUME_SIZE_STEP,
          rules: number()
            .integer()
            .min(
              MIN_VOLUME_SIZE,
              `Volume size must be greater than or equals to ${MIN_VOLUME_SIZE} GiB.`
            )
            .test(
              "isMultipleOf",
              `Volume size must be multiple of ${VOLUME_SIZE_STEP} GiB.`,
              (value) => Boolean(value && value % VOLUME_SIZE_STEP === 0)
            )
            .required()
        }
      ]
    },
    [DIALOG_TYPES.EDIT_VOLUME]: {
      onConfirm: handleConfirmEditVolume,
      title: "Edit volume",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)
              ?.description || "",
          label: "Description",
          rules: string()
        },
        {
          name: "bootable",
          type: FIELD_TYPES.CHECKBOX,
          label: "Is volume bootable",
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)?.bootable ||
            false
        }
      ]
    },
    [DIALOG_TYPES.EXTEND_VOLUME]: {
      onConfirm: handleConfirmExtendVolume,
      title: "Extend volume",
      confirmButtonLabel: "Extend",
      fields: [
        {
          name: "restartWarning",
          type: FIELD_TYPES.LABEL,
          isHidden: () =>
            volumes?.find((volume) => volume.id === selectedItemId)?.status ===
            VOLUME_STATUSES.AVAILABLE,
          label:
            "⚠️ Extension of this volume will restart the virtual machine it is attached to."
        },
        {
          name: "size",
          type: FIELD_TYPES.NUMBER,
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)?.size ||
            MIN_VOLUME_SIZE,
          label: "Size",
          suffix: "GiB",
          min: volumes?.find((volume) => volume.id === selectedItemId)?.size
            ? Number(
                volumes?.find((volume) => volume.id === selectedItemId)?.size
              )
            : MIN_VOLUME_SIZE,
          step: VOLUME_SIZE_STEP,
          rules: number()
            .integer()
            .min(
              volumes?.find((volume) => volume.id === selectedItemId)?.size
                ? Number(
                    volumes?.find((volume) => volume.id === selectedItemId)
                      ?.size
                  )
                : MIN_VOLUME_SIZE,
              `Volume size must be greater than or equals to ${
                volumes?.find((volume) => volume.id === selectedItemId)?.size
                  ? Number(
                      volumes?.find((volume) => volume.id === selectedItemId)
                        ?.size
                    )
                  : MIN_VOLUME_SIZE
              } GiB.`
            )
            .test(
              "isMultipleOf",
              `Volume size must be multiple of ${VOLUME_SIZE_STEP}.`,
              (value) => Boolean(value && value % VOLUME_SIZE_STEP === 0)
            )
            .required()
        }
      ]
    },
    [DIALOG_TYPES.ATTACH_VOLUME]: {
      onConfirm: handleConfirmAttachVolume,
      title: "Attach volume",
      confirmButtonLabel: "Attach",
      fields: [
        {
          name: "virtualMachine",
          type: FIELD_TYPES.SELECT,
          label: "Virtual machine",
          options: virtualMachines?.map((virtualMachine) =>
            getSelectOption(virtualMachine, "name", "id")
          ),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DETACH_VOLUME]: {
      onConfirm: handleConfirmDetachVolume,
      title: "Are you sure you want to detach next volume?",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.LABEL,
          label: `\u2794\u00A0\u00A0${deletingVolumeName ?? "selected"}`
        }
      ],
      confirmButtonLabel: "Detach"
    },
    [DIALOG_TYPES.DELETE_VOLUME]: {
      onConfirm: handleConfirmDeleteVolume,
      title: `Are you sure you want to delete next volume?`,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.LABEL,
          label: `\u2794\u00A0\u00A0${deletingVolumeName ?? "selected"}`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_VOLUMES]: {
      onConfirm:
        checkedRows?.length > 1
          ? handleConfirmDeleteVolumes
          : handleConfirmDeleteVolume,
      title: `Are you sure you want to delete next volumes?`,
      fields: [
        {
          name: "names",
          type: FIELD_TYPES.LABEL,
          label: deletingVolumesNames
            ? deletingVolumesNames
                .map((name) => `\u2794\u00A0\u00A0${name}`)
                .join("\n")
            : ""
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_VOLUME_FROM_SNAPSHOT]: {
      onConfirm: handleConfirmCreateVolumeFromSnapshot,
      title: "Create volume from snapshot",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.CREATE_SNAPSHOT]: {
      onConfirm: handleConfirmCreateSnapshot,
      title: "Create snapshot",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "volume",
          type: FIELD_TYPES.SELECT,
          label: "Volume",
          options: volumes?.map((volume) =>
            getSelectOption(volume, "detailedName", "id")
          ),
          rules: selectOptionSchema
        },
        {
          name: "inUseWarning",
          type: FIELD_TYPES.NOTES,
          label:
            '⚠️ Snapshot created from the volume with "in-use" status can contain corrupted data.'
        }
      ]
    },
    [DIALOG_TYPES.EDIT_SNAPSHOT]: {
      onConfirm: handleConfirmEditSnapshot,
      title: "Edit snapshot",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            snapshots?.find((snapshot) => snapshot.id === selectedItemId)
              ?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            snapshots?.find((snapshot) => snapshot.id === selectedItemId)
              ?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_SNAPSHOT]: {
      onConfirm: handleConfirmDeleteSnapshot,
      title: `Are you sure you want to delete "${
        deletingSnapshotName ?? "selected"
      }" snapshot?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.SHOW_CREDENTIALS]: {
      onConfirm: handleConfirmShowCredentials,
      title: "Are you sure you want to show access secret?",
      confirmButtonLabel: "Show"
    },
    [DIALOG_TYPES.CREATE_CREDENTIALS]: {
      onConfirm: handleConfirmCreateCredentials,
      title: "Are you sure you want to create new object storage credentials?",
      confirmButtonLabel: "Create"
    },
    [DIALOG_TYPES.REGENERATE_CREDENTIALS]: {
      onConfirm: handleConfirmReGenerateCredentials,
      title: "Are you sure you want to re-generate access secret?",
      confirmButtonLabel: "Re-generate secret"
    },
    [DIALOG_TYPES.DELETE_CREDENTIALS]: {
      onConfirm: handleConfirmDeleteCredentials,
      title: `Are you sure you want to delete current object storage credentials?`,
      confirmButtonLabel: "Delete"
    }
  };

  const handleCloseAccessSecretDialog = useCallback(() => {
    setIsAccessSecretDialogOpened(false);
    dispatch(objectStorageActions.clearCredentials());
  }, [dispatch]);

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {tabContent[activeTabIndex]}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      {credentials && isString(credentials.access_secret) && (
        <AccessSecretDialog
          isOpened={isAccessSecretDialogOpened}
          accessSecret={credentials.access_secret}
          onClose={handleCloseAccessSecretDialog}
        />
      )}
    </>
  );
};
