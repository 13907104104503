export enum DIALOG_TYPES {
  EDIT,
  DELETE
}

export enum DATE_TYPES {
  START,
  END
}

export enum TABS {
  SUMMARY,
  INSTANCES,
  VOLUMES,
  DATABASES
}
