import {
  InstanceMetrics,
  TableInstanceActionLogs
} from "modules/instances/types";
import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  Flavor,
  Image,
  Instance,
  InstanceFirewall,
  InstanceLimits,
  InstanceLog,
  SelectOptionFlavor,
  SOURCE_TYPES,
  TableImage,
  TableInterface,
  TableVirtualMachine
} from "./types";
import { createSelector } from "reselect";

export const SOURCE_TYPES_LABELS = {
  [SOURCE_TYPES.IMAGE]: "Image",
  [SOURCE_TYPES.VOLUME]: "Volume",
  [SOURCE_TYPES.SNAPSHOT]: "Snapshot"
};

export const instanceSelector = (state: RootReducer): Instance | null =>
  state.instances.instance;

export const instancesSelector = (state: RootReducer): Instance[] | null =>
  state.instances.instances;

export const tableVirtualMachinesSelector = (
  state: RootReducer
): TableVirtualMachine[] | null =>
  state.instances.instances?.map((instance) => ({
    id: instance.id,
    name: instance.name,
    status: instance.status,
    ip: state.networks.interfaces
      ? state.networks.interfaces
          .filter((inter) => inter.device_id === instance.id)
          .map((inter) => {
            const network = state.networks.networks?.find(
              (network) => network.id === inter.network_id
            );
            const floatingIPs = state.networks.floatingIPs;
            if (network && inter && floatingIPs) {
              if (inter.fixed_ips) {
                const associatedFloatingIP = floatingIPs.find(
                  (fip) => fip.port_id === inter.id
                );
                if (associatedFloatingIP) {
                  return `${network.name}:${
                    inter.fixed_ips[0].ip_address || ""
                  }, ${associatedFloatingIP.floating_ip_address} `;
                } else {
                  return `${network.name}:${
                    inter.fixed_ips[0].ip_address || ""
                  } `;
                }
              } else {
                return "";
              }
            }
          })
          .flat()
          .join("\n")
      : "",
    keypair: instance.key_name,
    vCPUs:
      state.instances.flavors?.find(
        (flavor) => flavor.id === instance.flavor_id
      )?.vcpus || 0,
    memory: state.instances.flavors
      ? `${
          (state.instances.flavors.find(
            (flavor) => flavor.id === instance.flavor_id
          )?.ram || 0) / 1024
        } GiB`
      : "",
    flavorId: instance.flavor_id,
    imageId: instance.image_id,
    created: formatDate(new Date(instance.created), DATE_FORMATS.DATETIME),
    tags: instance.tags,
    tagsString: instance.tags.join(", ")
  })) || null;

export const isInstanceLoadingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceLoading;

export const isInstanceCreatingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceCreating;

export const isInstanceUpdatingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceUpdating;

export const isInstanceResizingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceResizing;

export const isInstanceResizeConfirmingSelector = (
  state: RootReducer
): boolean => state.instances.isInstanceResizeConfirming;

export const isInstanceResizeRevertingSelector = (
  state: RootReducer
): boolean => state.instances.isInstanceResizeReverting;

export const isInstanceDeletingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceDeleting;

export const isInstanceStartingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceStarting;

export const isInstanceRestartingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceRestarting;

export const isInstanceStoppingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceStopping;

export const areInstancesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areInstancesLoading;

export const flavorSelector = (state: RootReducer): Flavor | null =>
  state.instances.flavor;

export const flavorsSelector = (state: RootReducer): Flavor[] | null =>
  state.instances.flavors;

export const selectOptionFlavorsSelector = createSelector(
  [flavorsSelector],
  (flavors) => {
    return flavors
      ? flavors.map((flavor) => ({
          id: flavor.id,
          name: `${flavor.name} (${flavor.vcpus} vCPU${
            flavor.vcpus > 1 ? "s" : ""
          }, ${flavor.ram / 1024} GiB memory)`
        }))
      : null;
  }
);

// export const selectOptionFlavorsSelector = (
//   state: RootReducer
// ): SelectOptionFlavor[] | null =>
//   state.instances.flavors?.map((flavor) => ({
//     id: flavor.id,
//     name: `${flavor.name} (${flavor.vcpus} vCPU${
//       flavor.vcpus > 1 ? "s" : ""
//     }, ${flavor.ram / 1024} GiB memory)`
//   })) || null;

export const isFlavorLoadingSelector = (state: RootReducer): boolean =>
  state.instances.isFlavorLoading;

export const areFlavorsLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areFlavorsLoading;

// export const tableImagesSelector = (state: RootReducer): TableImage[] | null =>
//   state.instances.images?.map((image) => ({
//     id: image.id,
//     name: image.name,
//     status: image.status,
//     size: `${(image.size / 1024 / 1024 / 1024).toFixed(2)} GiB`,
//     createdAt: formatDate(new Date(image.created_at), DATE_FORMATS.DATETIME),
//     osPlatform: image.os_platform,
//     osDistro: image.os_distro,
//     osVersion: image.os_version,
//     osDefaultUser: image.os_default_user,
//     isDeprecated: image.deprecated
//   })) || null;

export const imagesSelector = (state: RootReducer): Image[] | null =>
  state.instances.images;

export const tableImagesSelector = createSelector(
  [imagesSelector],
  (images) => {
    return (
      images?.map((image) => ({
        id: image.id,
        name: image.name,
        status: image.status,
        size: `${(image.size / 1024 / 1024 / 1024).toFixed(2)} GiB`,
        createdAt: formatDate(
          new Date(image.created_at),
          DATE_FORMATS.DATETIME
        ),
        osPlatform: image.os_platform,
        osDistro: image.os_distro,
        osVersion: image.os_version,
        osDefaultUser: image.os_default_user,
        isDeprecated: image.deprecated
      })) || null
    );
  }
);

export const imageSelector = (state: RootReducer): Image | null =>
  state.instances.image;

export const areImagesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areImagesLoading;

// export const tableInterfacesSelector = (
//   state: RootReducer
// ): TableInterface[] | null =>
//   state.instances.interfaces?.map((i) => ({
//     ip: state.networks.floatingIPs?.find((fip) => fip.port_id === i.id)
//       ? `${i.fixed_ips ? i.fixed_ips[0].ip_address : ""}, ${
//           state.networks.floatingIPs.find((fip) => fip.port_id === i.id)
//             ?.floating_ip_address || ""
//         }`
//       : `${i.fixed_ips ? i.fixed_ips[0].ip_address : ""}`,
//     id: i.id,
//     subnetId: `${i.fixed_ips ? i.fixed_ips[0].subnet_id : ""}`,
//     name:
//       state.networks.subnets?.find((subnet) => {
//         if (i.fixed_ips) {
//           return subnet.id === i.fixed_ips[0].subnet_id;
//         }
//       })?.name || "",
//     macAddr: i.mac_address,
//     status: i.status,
//     firewalls: i.security_groups?.join(";\n") || "",
//     networkName: state.networks.networks?.find(
//       (network) => network.id === i.network_id
//     )?.name,
//     networkId: i.network_id
//   })) || null;

const getInterfaces = (state: RootReducer) => state.instances.interfaces;
const getFloatingIPs = (state: RootReducer) => state.networks.floatingIPs;
const getSubnets = (state: RootReducer) => state.networks.subnets;
const getNetworks = (state: RootReducer) => state.networks.networks;
export const tableInterfacesSelector = createSelector(
  [getInterfaces, getFloatingIPs, getSubnets, getNetworks],
  (interfaces, floatingIPs, subnets, networks) => {
    return (
      interfaces?.map((i) => ({
        ip: floatingIPs?.find((fip) => fip.port_id === i.id)
          ? `${i.fixed_ips ? i.fixed_ips[0].ip_address : ""}, ${
              floatingIPs.find((fip) => fip.port_id === i.id)
                ?.floating_ip_address || ""
            }`
          : `${i.fixed_ips ? i.fixed_ips[0].ip_address : ""}`,
        id: i.id,
        subnetId: `${i.fixed_ips ? i.fixed_ips[0].subnet_id : ""}`,
        name:
          subnets?.find(
            (subnet) => i.fixed_ips && subnet.id === i.fixed_ips[0].subnet_id
          )?.name || "",
        macAddr: i.mac_address,
        status: i.status,
        firewalls: i.security_groups?.join(";\n") || "",
        networkName: networks?.find((network) => network.id === i.network_id)
          ?.name,
        networkId: i.network_id
      })) || null
    );
  }
);

export const areInterfacesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areInterfacesLoading;

export const isInterfaceCreatingSelector = (state: RootReducer): boolean =>
  state.instances.isInterfaceCreating;

export const isInterfaceDeletingSelector = (state: RootReducer): boolean =>
  state.instances.isInterfaceDeleting;

export const instanceLimitsSelector = (
  state: RootReducer
): InstanceLimits | null =>
  (state.instances.instanceLimits && state.instances.instanceLimits[0]) || null;

export const areInstanceLimitsLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areInstanceLimitsLoading;

export const isVolumeAttachingSelector = (state: RootReducer): boolean =>
  state.instances.isVolumeAttaching;

export const isVolumeDetachingSelector = (state: RootReducer): boolean =>
  state.instances.isVolumeDetaching;

export const isFirewallAddingSelector = (state: RootReducer): boolean =>
  state.instances.isFirewallAdding;

export const isFirewallRemovingSelector = (state: RootReducer): boolean =>
  state.instances.isFirewallRemoving;

export const instanceFirewallsSelector = (
  state: RootReducer
): InstanceFirewall[] | null => state.instances.instanceFirewalls;

export const areInstanceFirewallsLoadingSelector = (
  state: RootReducer
): boolean => state.instances.areInstanceFirewallsLoading;

export const instanceLogSelector = (state: RootReducer): InstanceLog | null =>
  state.instances.instanceLog;

export const isInstanceLogLoadingSelector = (state: RootReducer): boolean =>
  state.instances.isInstanceLogLoading;

export const instanceMetricsSelector = (
  state: RootReducer
): InstanceMetrics | null => state.instances.instanceMetrics;

export const areInstanceMetricsLoadingSelector = (
  state: RootReducer
): boolean => state.instances.areInstanceMetricsLoading;

export const instanceActionLogsSelector = (
  state: RootReducer
): TableInstanceActionLogs[] | null =>
  state.instances.instanceActionLogs?.map((log) => ({
    ...log,
    id: log.request_id,
    start_time: formatDate(new Date(log.start_time), DATE_FORMATS.DATETIME)
  })) || null;

export const areInstanceActionLogsLoadingSelector = (
  state: RootReducer
): boolean => state.instances.areInstanceActionLogsLoading;
