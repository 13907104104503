import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  DatabaseBackupStatus,
  DatabaseResetPassword,
  DATABASES,
  DbaasQuotas,
  DBServiceParams,
  TableCronStatus,
  TableDatabase,
  TableDatabaseBackupFiles,
  TableReplicaDatabase
} from "./types";

export const DATABASES_LABELS = {
  [DATABASES.MYSQL]: "MySQL",
  [DATABASES.MARIADB]: "MariaDB",
  [DATABASES.POSTGRESQL]: "PostgreSQL"
};

export const DATABASES_DEFAULT_PORTS = {
  [DATABASES.MYSQL]: 3306,
  [DATABASES.MARIADB]: 3306,
  [DATABASES.POSTGRESQL]: 5432
};

export const tableDatabasesSelector = (
  state: RootReducer
): TableDatabase[] | null =>
  state.databases.databases?.map((database) => ({
    id: database.id,
    project_id: database.project_id,
    name: database.name,
    connection_string: database.connection_string,
    connectionString: database.connection_string,
    version: database.version,
    versionString: `${String(DATABASES_LABELS[database.service_name])} ${
      database.version
    }`,
    region: database.region,
    cpu: database.cpu,
    cpuString: `${database.cpu / 1000}`,
    memory: database.memory,
    memoryString: `${String(database.memory / 1024)} GiB`,
    disk: database.disk,
    diskString: `${String(database.disk)} MiB`,
    volume_capacity_used: database.volume_capacity_used,
    volumeCapacity: `${String(database.volume_capacity_used)}`,
    hw_load: database.hw_load,
    service_name: database.service_name,
    serviceName: database.service_name,
    service_status: database.service_status,
    mode: database.mode,
    port: database.port,
    password: database.password,
    user: database.user,
    creation_time: database.creation_time,
    ageString: formatDate(
      new Date(database.creation_time * 1000),
      DATE_FORMATS.DATETIME
    ),
    ipACL: database.ip_acl || [],
    ip_acl: database.ip_acl,
    region_instance: database.region_instance,
    subnet_id: database.subnet_id,
    org_id: database.org_id
  })) || null;

export const databaseSelector = (state: RootReducer): TableDatabase | null =>
  state.databases.database
    ? {
        id: state.databases.database.id,
        project_id: state.databases.database.project_id,
        name: state.databases.database.name,
        connection_string: state.databases.database.connection_string,
        connectionString: state.databases.database.connection_string,
        version: state.databases.database.version,
        versionString: `${String(
          DATABASES_LABELS[state.databases.database.service_name]
        )} ${state.databases.database.version}`,
        region: state.databases.database.region,
        cpu: state.databases.database.cpu,
        cpuString: `${state.databases.database.cpu / 1000}`,
        memory: state.databases.database.memory,
        memoryString: `${String(state.databases.database.memory / 1024)} GiB`,
        disk: state.databases.database.disk,
        diskString: `${String(state.databases.database.disk)} MiB`,
        volume_capacity_used: state.databases.database.volume_capacity_used,
        volumeCapacity: `${String(
          state.databases.database.volume_capacity_used
        )}`,
        hw_load: state.databases.database.hw_load,
        service_status: state.databases.database.service_status,
        mode: state.databases.database.mode,
        port: state.databases.database.port,
        password: state.databases.database.password,
        user: state.databases.database.user,
        service_name: state.databases.database.service_name,
        serviceName: state.databases.database.service_name,
        creation_time: state.databases.database.creation_time,
        ageString: formatDate(
          new Date(state.databases.database.creation_time * 1000),
          DATE_FORMATS.DATETIME
        ),
        ipACL: state.databases.database.ip_acl || [],
        ip_acl: state.databases.database.ip_acl,
        region_instance: state.databases.database.region_instance,
        subnet_id: state.databases.database.subnet_id,
        org_id: state.databases.database.org_id
      }
    : null;

export const DBServiceParamsSelector = (
  state: RootReducer
): DBServiceParams | null => state.databases.DBServiceParams;

export const DBServicesParamsSelector = (
  state: RootReducer
): DBServiceParams[] | null => state.databases.DBServicesParams;

export const tableReplicaDatabasesSelector = (
  state: RootReducer
): TableReplicaDatabase[] | null =>
  state.databases.replicaDatabases?.map((database) => ({
    id: database.id,
    project_id: database.project_id,
    name: database.name,
    connection_string: database.connection_string,
    connectionString: database.connection_string,
    version: database.version,
    versionString: `${String(DATABASES_LABELS[database.service_name])} ${
      database.version
    }`,
    region: database.region,
    cpu: database.cpu,
    cpuString: `${database.cpu / 1000}`,
    memory: database.memory,
    memoryString: `${String(database.memory / 1024)} GiB`,
    disk: database.disk,
    diskString: `${String(database.disk)} MiB`,
    volume_capacity_used: database.volume_capacity_used,
    volumeCapacity: `${String(database.volume_capacity_used)}`,
    hw_load: database.hw_load,
    service_name: database.service_name,
    serviceName: database.service_name,
    service_status: database.service_status,
    mode: database.mode,
    port: database.port,
    password: database.password,
    user: database.user,
    creation_time: database.creation_time,
    ageString: formatDate(
      new Date(database.creation_time * 1000),
      DATE_FORMATS.DATETIME
    ),
    ipACL: database.ip_acl || [],
    ip_acl: database.ip_acl,
    region_instance: database.region_instance,
    subnet_id: database.subnet_id,
    org_id: database.org_id
  })) || null;

export const backupStatusSelector = (
  state: RootReducer
): DatabaseBackupStatus | null => state.databases.databaseBackupStatus;

export const backupFilesSelector = (
  state: RootReducer
): TableDatabaseBackupFiles[] | null =>
  state.databases.databaseBackupFiles?.map((file) => ({
    id: file.etag,
    etag: file.etag,
    file_name: file.file_name,
    file_size: file.file_size,
    fileSize: `${String((file.file_size / 1024 / 1024).toFixed(2))} MiB`
  })) || null;

export const cronStatusSelector = (
  state: RootReducer
): TableCronStatus[] | null =>
  state.databases.databaseBackupStatus?.cron_runs?.map((cron) => ({
    id: cron.id,
    date: cron.date,
    status: cron.status,
    dateString: formatDate(new Date(cron.date * 1000), DATE_FORMATS.DATETIME)
  })) || null;

export const areDatabaseBackupFilesLoadingSelector = (
  state: RootReducer
): boolean => state.databases.areDatabaseBackupFilesLoading;

export const isDatabaseLoadingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseLoading;

export const areDatabasesLoadingSelector = (state: RootReducer): boolean =>
  state.databases.areDatabasesLoading;

export const isDatabaseGettingManagementUrlSelector = (
  state: RootReducer
): boolean => state.databases.isDatabaseGettingManagementUrl;

export const databaseResetPasswordSelector = (
  state: RootReducer
): DatabaseResetPassword | null =>
  state.databases.databaseResetPassword || null;

export const areReplicaDatabasesLoadingSelector = (
  state: RootReducer
): boolean => state.databases.areReplicaDatabasesLoading;

export const dbaasQuotasSelector = (state: RootReducer): DbaasQuotas | null =>
  state.databases.dbaasQuotas || null;

export const areDbaasQuotasLoadingSelector = (state: RootReducer): boolean =>
  state.databases.areDbaasQuotasLoading;

export const isDatabaseCreatingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseCreating;

export const isDatabaseUpdatingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseUpdating;

export const isDatabaseDeletingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseDeleting;

export const isDatabaseRestoringSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseRestoring;

export const isDatabaseBackingUpSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseBackingUp;

export const isDatabaseS3LinkingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseS3Linking;

export const isDatabaseS3UnlinkingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseS3Unlinking;

export const isCronBackupUpdatingSelector = (state: RootReducer): boolean =>
  state.databases.isCronBackupUpdating;

export const isCronBackupDeletingSelector = (state: RootReducer): boolean =>
  state.databases.isCronBackupDeleting;

export const areDBServiceParamsLoadingSelector = (
  state: RootReducer
): boolean => state.databases.areDBServiceParamsLoading;

export const areAllServicesParamsLoadingSelector = (
  state: RootReducer
): boolean => state.databases.areDBServicesParamsLoading;

export const isDatabaseStatusChangingSelector = (state: RootReducer): boolean =>
  state.databases.isDatabaseStatusChanging;

export const isDatabasePasswordResettingSelector = (
  state: RootReducer
): boolean => state.databases.isDatabasePasswordResetting;
