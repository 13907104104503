import FailedIcon from "@mui/icons-material/ClearRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import SuccessIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { Tab, Tabs } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { TableCollapsible } from "components/common/TableCollapsible";
import { CollapsibleData } from "components/common/TableCollapsible/types";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CircularProgressWithLabel } from "components/common/CircularProgressWithLabel";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { addDays, subDays } from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import sub from "date-fns/sub";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as billingActions from "modules/billing/actions";
import {
  isProjectBillingDatabasesDataLoadingSelector,
  isProjectBillingInstancesDataLoadingSelector,
  isProjectBillingVolumesDataLoadingSelector,
  projectBillingInstancesDataSelector,
  tableProjectBillingDatabasesRecordsSelector,
  tableProjectBillingDatabasesRecordsTotalSelector,
  tableProjectBillingInstancesRecordsSelector,
  tableProjectBillingInstancesRecordsTotalSelector,
  tableProjectBillingSummaryRecordsSelector,
  tableProjectBillingSummaryRecordsTotalSelector,
  tableProjectBillingVolumesRecordsSelector,
  tableProjectBillingVolumesRecordsTotalSelector
} from "modules/billing/selectors";
import {
  TableBillingDatabasesRecord,
  TableBillingInstancesRecord,
  TableBillingSummaryRecord,
  TableBillingVolumesRecord
} from "modules/billing/types";
import * as databasesActions from "modules/databases/actions";
import { dbaasQuotasSelector } from "modules/databases/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import { ROLES } from "modules/enterprises/types";
import * as instancesActions from "modules/instances/actions";
import { instanceLimitsSelector } from "modules/instances/selectors";
import * as networksActions from "modules/networks/actions";
import { networkLimitsSelector } from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import {
  isProjectDeletingSelector,
  isProjectUpdatingSelector,
  projectSelector
} from "modules/projects/selectors";
import * as volumesActions from "modules/storage/actions";
import { volumeLimitsSelector } from "modules/storage/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { getParentPath } from "utils/getParentPath";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { appConfig } from "../../appConfig";
import {
  DATE_FORMATS,
  DEFAULT_CURRENCY,
  ERROR_MESSAGES,
  PROJECT_NAME_LENGTH,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DATE_TYPES, DIALOG_TYPES, TABS } from "./types";

const CURRENCY_SELECT_ID = "currency";

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.SUMMARY]: "Billing Summary",
  [TABS.INSTANCES]: "Instances",
  [TABS.VOLUMES]: "Volumes",
  [TABS.DATABASES]: "Databases"
};

const POLL_ID_PREFIX = "PROJECT";

const POLL_IDS = {
  dbaasQuotas: "DBAAS_QUOTAS",
  instanceLimits: "INSTANCE_LIMITS",
  volumeLimits: "VOLUME_LIMITS",
  networkLimits: "NETWORK_LIMITS",
  projectBillingData: "PROJECT_BILLING_DATA"
};

// const tableColumns: TableColumn<TableBillingRecord>[] = [
//   { key: "name", label: "Resource type" },
//   { key: "description", label: "Description" }
// ];

export const Project: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    serviceName: string;
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const prevOrganization = usePrevious(organization);
  const project = useSelector(projectSelector);
  const dbaasQuotas = useSelector(dbaasQuotasSelector);
  const instanceLimits = useSelector(instanceLimitsSelector);
  const volumeLimits = useSelector(volumeLimitsSelector);
  const networkLimits = useSelector(networkLimitsSelector);
  // const billingData = useSelector(projectBillingDataSelector);
  // const tableBillingData = useSelector(tableProjectBillingRecordsSelector);
  // const tableBillingDataTotal = useSelector(
  //   tableProjectBillingRecordsTotalSelector
  // );
  // const isBillingDataLoading = useSelector(isProjectBillingDataLoadingSelector);
  const isProjectUpdating = useSelector(isProjectUpdatingSelector);
  const isProjectDeleting = useSelector(isProjectDeletingSelector);
  const isOperationInProgress = isProjectUpdating || isProjectDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const summaryTableBillingData = useSelector(
    tableProjectBillingSummaryRecordsSelector
  );

  const summaryTableBillingDataTotal = useSelector(
    tableProjectBillingSummaryRecordsTotalSelector
  );

  const instancesBillingData = useSelector(projectBillingInstancesDataSelector);
  const instancesTableBillingData = useSelector(
    tableProjectBillingInstancesRecordsSelector
  );
  const instancesTableBillingDataTotal = useSelector(
    tableProjectBillingInstancesRecordsTotalSelector
  );

  const volumesTableBillingData = useSelector(
    tableProjectBillingVolumesRecordsSelector
  );
  const volumesTableBillingDataTotal = useSelector(
    tableProjectBillingVolumesRecordsTotalSelector
  );

  const databasesTableBillingData = useSelector(
    tableProjectBillingDatabasesRecordsSelector
  );
  const databasesTableBillingDataTotal = useSelector(
    tableProjectBillingDatabasesRecordsTotalSelector
  );

  const isProjectBillingInstancesDataLoading = useSelector(
    isProjectBillingInstancesDataLoadingSelector
  );
  const isProjectBillingVolumesDataLoading = useSelector(
    isProjectBillingVolumesDataLoadingSelector
  );
  const isProjectBillingDatabasesDataLoading = useSelector(
    isProjectBillingDatabasesDataLoadingSelector
  );

  const currentDate = new Date();
  const minDate = sub(currentDate, { days: 120 });
  const [startDate, setStartDate] = useState<string | null>(
    formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );
  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);

  const tableCurrencies = useMemo(
    () => (instancesBillingData ? Object.keys(instancesBillingData.costs) : []),
    [instancesBillingData]
  );
  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  const tableSummaryColumns: TableColumn<TableBillingSummaryRecord>[] = [
    { key: "id", label: "Resource Type", width: "50%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];

  const tableVolumesColumns: TableColumn<TableBillingVolumesRecord>[] = [
    { key: "id", label: "Volume ID" },
    { key: "name", label: "Volume Name" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleVolumesColumns: TableColumn<CollapsibleData>[] = [
    { key: "description", label: "Size" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const tableInstancesColumns: TableColumn<TableBillingInstancesRecord>[] = [
    { key: "id", label: "Instance ID" },
    { key: "name", label: "Instance Name" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleInstanceColumns: TableColumn<CollapsibleData>[] = [
    { key: "name", label: "Flavor Name" },
    { key: "description", label: "Description" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const tableDatabasesColumns: TableColumn<TableBillingVolumesRecord>[] = [
    { key: "id", label: "Database ID" },
    { key: "name", label: "Database Name" },
    { key: "type", label: "Type" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleDatabasesColumns: TableColumn<CollapsibleData>[] = [
    { key: "name", label: "Description" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.SUMMARY) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const handleEditProjectButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteProjectButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.instanceLimits}`,
        action: projectsActions.getProject.started({
          regionId: matchParams.regionId!,
          id: matchParams.projectId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.instanceLimits}`,
        action: instancesActions.getInstanceLimits.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.volumeLimits}`,
        action: volumesActions.getVolumeLimits.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.networkLimits}`,
        action: networksActions.getNetworkLimits.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.dbaasQuotas}`,
        action: databasesActions.getDbaasQuotas.started({
          serviceName: matchParams.serviceName!,
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(billingActions.clear());
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    if (
      prevOrganization !== undefined &&
      organization &&
      [ROLES.OWNER, ROLES.ADMIN].includes(organization.role) &&
      startDate &&
      endDate
    ) {
      dispatch(
        billingActions.getProjectBillingInstancesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingVolumesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingDatabasesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    organization,
    dispatch,
    startDate,
    endDate,
    matchParams.organizationId,
    matchParams.regionId,
    matchParams.projectId,
    prevOrganization
  ]);

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      if (!project) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          projectsActions.getProject.started({
            regionId: matchParams.regionId!,
            id: matchParams.projectId!
          })
        );
      }
    }
  }, [
    project,
    history,
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const handleConfirmEditProject = useCallback(
    (data: { name: string }) => {
      dispatch(
        projectsActions.updateProject.started({
          organizationId: matchParams.organizationId!,
          regionId: matchParams.regionId!,
          id: matchParams.projectId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId
    ]
  );

  const handleConfirmDeleteProject = useCallback(() => {
    dispatch(
      projectsActions.deleteProject.started({
        organizationId: matchParams.organizationId!,
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.organizationId,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditProject,
      title: "Edit project",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: project?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteProject,
      title: `Are you sure you want to delete "${
        project?.name ?? "selected"
      }" project?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the project name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Project name does not match",
              test: function (value) {
                return value === project?.name;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  const title = project?.name;

  const renderMetric = (
    title: string,
    value: number,
    maxValue: number,
    unit?: string
  ) => (
    <s.MetricContainer>
      <s.MetricTitle variant={"h6"}>{title}</s.MetricTitle>
      <CircularProgressWithLabel
        value={value}
        maxValue={maxValue}
        unit={unit}
      />
    </s.MetricContainer>
  );

  const toolbarItems = (
    <>
      <MobileDatePicker
        label={"Start date"}
        onChange={handleDateChange(DATE_TYPES.START)}
        value={startDate ? new Date(startDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={minDate}
        maxDate={endDate ? subDays(new Date(endDate), 1) : undefined}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      <MobileDatePicker
        label={"End date"}
        onChange={handleDateChange(DATE_TYPES.END)}
        value={endDate ? new Date(endDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={startDate ? addDays(new Date(startDate), 1) : minDate}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      {tableCurrencies.length > 0 && (
        <s.CurrencyFormControl>
          <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
          <Select
            label={"Currency"}
            labelId={CURRENCY_SELECT_ID}
            value={currency}
            onChange={handleCurrencyChange}
            size={"small"}
          >
            {tableCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </s.CurrencyFormControl>
      )}
    </>
  );

  const tabContent = [
    <TableCollapsible<TableBillingSummaryRecord>
      key={"summaryBilling"}
      isSearchEnabled={true}
      rows={summaryTableBillingData || []}
      // collapsibleDataKey="items"
      // collapsibleDataColumns={[
      //   ...collapsibleSummaryColumns,
      // ]}
      columns={[...tableSummaryColumns]}
      totalRow={summaryTableBillingDataTotal || undefined}
      isLoading={
        isProjectBillingInstancesDataLoading ||
        isProjectBillingVolumesDataLoading ||
        isProjectBillingDatabasesDataLoading
      }
      toolbarItems={toolbarItems}
    />,
    <TableCollapsible<TableBillingInstancesRecord>
      key={"InstancesBilling"}
      rows={instancesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingInstancesDataLoading}
      isSearchEnabled={true}
      totalRow={instancesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableInstancesColumns]}
      collapsibleDataColumns={[...collapsibleInstanceColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (value) => {
          if (value === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (value === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.status === "deleted")
            return `deleted at ${String(row.deleted)}`;
          if (row.status === "available") return "available instance";
          return "";
        }
      }}
    />,
    <TableCollapsible<TableBillingVolumesRecord>
      key={"volumesBilling"}
      rows={volumesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingVolumesDataLoading}
      isSearchEnabled={true}
      totalRow={volumesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableVolumesColumns]}
      collapsibleDataColumns={[...collapsibleVolumesColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (value) => {
          if (value === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (value === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.deleted === "true") return `deleted volume`;
          if (row.deleted === "false") return "available volume";
          return "";
        }
      }}
    />,
    <TableCollapsible<TableBillingDatabasesRecord>
      key={"databasesBilling"}
      rows={databasesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingDatabasesDataLoading}
      isSearchEnabled={true}
      totalRow={databasesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableDatabasesColumns]}
      collapsibleDataColumns={[...collapsibleDatabasesColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (value) => {
          if (value === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (value === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.deleted === "true") return `deleted database`;
          if (row.deleted === "false") return "available database";
          return "";
        }
      }}
    />
  ];

  return (
    <>
      <Head title={title} />
      {project ? (
        <>
          {organization && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>

            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditProjectButtonClick}
                    color={"inherit"}
                    disabled={
                      !organization ||
                      ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
                    }
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteProjectButtonClick}
                    color={"inherit"}
                    disabled={
                      !organization ||
                      ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
                    }
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          {instanceLimits && volumeLimits && networkLimits ? (
            <>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{project.id}</s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Region: </s.DetailsTitle>
                <s.Tag label={instanceLimits?.region} />
              </s.SummaryRow>
              <s.MetricsContainer variant={"outlined"}>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Virtual machines",
                    instanceLimits.absolute.totalInstancesUsed,
                    instanceLimits.absolute.maxTotalInstances
                  )}
                  {renderMetric(
                    "VM total vCPUs",
                    instanceLimits.absolute.totalCoresUsed,
                    instanceLimits.absolute.maxTotalCores
                  )}
                  {renderMetric(
                    "VM total memory",
                    instanceLimits.absolute.totalRAMUsed / 1024,
                    instanceLimits.absolute.maxTotalRAMSize / 1024,
                    "GiB"
                  )}
                </s.MetricRowContainer>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Volumes",
                    volumeLimits.absolute.totalVolumesUsed,
                    volumeLimits.absolute.maxTotalVolumes
                  )}
                  {renderMetric(
                    "Volume snapshots",
                    volumeLimits.absolute.totalSnapshotsUsed,
                    volumeLimits.absolute.maxTotalSnapshots
                  )}
                  {renderMetric(
                    "Volume storage",
                    volumeLimits.absolute.totalGigabytesUsed,
                    volumeLimits.absolute.maxTotalVolumeGigabytes,
                    "GiB"
                  )}
                </s.MetricRowContainer>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Networks",
                    networkLimits.network_used,
                    networkLimits.network_limits
                  )}
                  {renderMetric(
                    "Firewalls",
                    networkLimits.security_groups_used,
                    networkLimits.security_groups_limits
                  )}
                  {renderMetric(
                    "Firewall rules",
                    networkLimits.security_groups_rules_used,
                    networkLimits.security_groups_rules_limits
                  )}
                </s.MetricRowContainer>
                {appConfig.isDbaasEnabled && dbaasQuotas ? (
                  <>
                    <s.MetricRowContainer>
                      {renderMetric(
                        "Databases",
                        dbaasQuotas.current_value_of_instance,
                        dbaasQuotas.max_value_of_instance
                      )}
                      {renderMetric(
                        "DB total vCPUs",
                        dbaasQuotas.current_value_of_cpu / 1000,
                        dbaasQuotas.max_value_of_cpu / 1000
                      )}
                      {renderMetric(
                        "DB total memory",
                        dbaasQuotas.current_value_of_memory / 1024,
                        dbaasQuotas.max_value_of_memory / 1024,
                        "GiB"
                      )}
                    </s.MetricRowContainer>
                    <s.MetricRowContainer>
                      {renderMetric(
                        "DB total storage",
                        dbaasQuotas.current_value_of_disk / 1024,
                        dbaasQuotas.max_value_of_disk / 1024,
                        "GiB"
                      )}
                    </s.MetricRowContainer>
                  </>
                ) : null}
              </s.MetricsContainer>
            </>
          ) : (
            <Loader text={"Loading data..."} />
          )}
          {organization &&
            [ROLES.OWNER, ROLES.ADMIN].includes(organization.role) && (
              <s.CostsContainer>
                <s.CostsTitle variant={"h5"} component={"h5"}>
                  Costs
                </s.CostsTitle>
                <s.TabsContainer>
                  <Tabs value={activeTabIndex} onChange={handleChangeTab}>
                    {Object.values(TAB_TITLES).map((tab) => (
                      <Tab key={tab} label={tab} />
                    ))}
                  </Tabs>
                </s.TabsContainer>
                <s.Description>
                  This billing data is only for the initial reference. The final
                  costs will be provided in the invoice.
                </s.Description>
                <s.Description2>
                  Billing data is available for previous 120 days
                </s.Description2>

                {tabContent[activeTabIndex]}
              </s.CostsContainer>
            )}
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
