import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  DBManagementUrl,
  DBServiceParams,
  Database,
  DatabaseBackupFile,
  DatabaseBackupStatus,
  DatabaseResetPassword,
  DbaasQuotas
} from "./types";

export type Reducer = {
  database: Database | null;
  databaseResetPassword: DatabaseResetPassword | null;
  replicaDatabase: Database | null;
  databases: Database[] | null;
  replicaDatabases: Database[] | null;
  databaseBackupStatus: DatabaseBackupStatus | null;
  databaseBackupFiles: DatabaseBackupFile[] | null;
  dbManagementUrl: DBManagementUrl | null;
  DBServiceParams: DBServiceParams | null;
  DBServicesParams: DBServiceParams[] | null;
  isDatabaseLoading: boolean;
  dbaasQuotas: DbaasQuotas | null;
  areDbaasQuotasLoading: boolean;
  isDatabaseBackupStatusLoading: boolean;
  areDatabaseBackupFilesLoading: boolean;
  isDatabaseRestoring: boolean;
  isDatabaseBackingUp: boolean;
  isDatabaseS3Linking: boolean;
  isDatabaseS3Unlinking: boolean;
  areDatabasesLoading: boolean;
  areReplicaDatabasesLoading: boolean;
  isCronBackupUpdating: boolean;
  isCronBackupDeleting: boolean;
  isReplicaDatabaseCreating: boolean;
  isDatabaseCreating: boolean;
  isDatabaseUpdating: boolean;
  isDatabaseDeleting: boolean;
  isDatabaseGettingManagementUrl: boolean;
  areDBServiceParamsLoading: boolean;
  areDBServicesParamsLoading: boolean;
  isDatabaseStatusChanging: boolean;
  isDatabasePasswordResetting: boolean;
};

const initialState: Reducer = {
  database: null,
  databaseResetPassword: null,
  replicaDatabase: null,
  databases: null,
  replicaDatabases: null,
  databaseBackupStatus: null,
  databaseBackupFiles: null,
  dbManagementUrl: null,
  DBServiceParams: null,
  DBServicesParams: null,
  isDatabaseLoading: false,
  dbaasQuotas: null,
  areDbaasQuotasLoading: false,
  isDatabaseBackupStatusLoading: false,
  areDatabaseBackupFilesLoading: false,
  isDatabaseRestoring: false,
  isDatabaseBackingUp: false,
  isDatabaseS3Linking: false,
  isDatabaseS3Unlinking: false,
  isCronBackupUpdating: false,
  isCronBackupDeleting: false,
  areDatabasesLoading: false,
  areReplicaDatabasesLoading: false,
  isReplicaDatabaseCreating: false,
  isDatabaseCreating: false,
  isDatabaseUpdating: false,
  isDatabaseDeleting: false,
  isDatabaseGettingManagementUrl: false,
  areDBServiceParamsLoading: false,
  areDBServicesParamsLoading: false,
  isDatabaseStatusChanging: false,
  isDatabasePasswordResetting: false
};

export const reducer = reducerWithInitialState(initialState)
  // Get database
  .case(
    actions.getDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseLoading: true
    })
  )
  .case(
    actions.getDatabase.done,
    (state, payload): Reducer => ({
      ...state,
      database: payload.result,
      isDatabaseLoading: false
    })
  )
  .case(
    actions.getDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseLoading: false
    })
  )
  // Get databases
  .case(
    actions.getDatabases.started,
    (state): Reducer => ({
      ...state,
      areDatabasesLoading: true
    })
  )
  .case(
    actions.getDatabases.done,
    (state, payload): Reducer => ({
      ...state,
      databases: payload.result,
      areDatabasesLoading: false
    })
  )
  .case(
    actions.getDatabases.failed,
    (state): Reducer => ({
      ...state,
      areDatabasesLoading: false
    })
  )
  // Create database
  .case(
    actions.createDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseCreating: true
    })
  )
  .case(
    actions.createDatabase.done,
    (state, payload): Reducer => ({
      ...state,
      isDatabaseCreating: false,
      database: payload.result
    })
  )
  .case(
    actions.createDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseCreating: false
    })
  )
  // Update database
  .case(
    actions.updateDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseUpdating: true
    })
  )
  .case(
    actions.updateDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseUpdating: true
    })
  )
  .case(
    actions.updateDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseUpdating: false
    })
  )
  // Delete database
  .case(
    actions.deleteDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseDeleting: true
    })
  )
  .case(
    actions.deleteDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseDeleting: false
    })
  )
  .case(
    actions.deleteDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseDeleting: false
    })
  )

  // Get Mgr URL
  .case(
    actions.getManagementUrl.started,
    (state): Reducer => ({
      ...state,
      isDatabaseGettingManagementUrl: true
    })
  )
  .case(
    actions.getManagementUrl.done,
    (state, payload): Reducer => ({
      ...state,
      dbManagementUrl: payload.result,
      isDatabaseGettingManagementUrl: false
    })
  )
  .case(
    actions.getManagementUrl.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseGettingManagementUrl: false
    })
  )

  // Get DB service params
  .case(
    actions.getDBServiceParams.started,
    (state): Reducer => ({
      ...state,
      areDBServiceParamsLoading: true
    })
  )
  .case(
    actions.getDBServiceParams.done,
    (state, payload): Reducer => ({
      ...state,
      DBServiceParams: payload.result,
      areDBServiceParamsLoading: false
    })
  )
  .case(
    actions.getDBServiceParams.failed,
    (state): Reducer => ({
      ...state,
      areDBServiceParamsLoading: false
    })
  )

  // Get DB quotas
  .case(
    actions.getDbaasQuotas.started,
    (state): Reducer => ({
      ...state,
      areDbaasQuotasLoading: true
    })
  )
  .case(
    actions.getDbaasQuotas.done,
    (state, payload): Reducer => ({
      ...state,
      dbaasQuotas: payload.result,
      areDbaasQuotasLoading: false
    })
  )
  .case(
    actions.getDbaasQuotas.failed,
    (state): Reducer => ({
      ...state,
      areDbaasQuotasLoading: false
    })
  )

  // Get all services params
  .case(
    actions.getDBServicesParams.started,
    (state): Reducer => ({
      ...state,
      areDBServicesParamsLoading: true
    })
  )
  .case(
    actions.getDBServicesParams.done,
    (state, payload): Reducer => ({
      ...state,
      DBServicesParams: payload.result,
      areDBServicesParamsLoading: false
    })
  )
  .case(
    actions.getDBServicesParams.failed,
    (state): Reducer => ({
      ...state,
      areDBServicesParamsLoading: false
    })
  )
  // Change database status
  .case(
    actions.changeDatabaseStatus.started,
    (state): Reducer => ({
      ...state,
      isDatabaseStatusChanging: true
    })
  )
  .case(
    actions.changeDatabaseStatus.done,
    (state): Reducer => ({
      ...state,
      isDatabaseStatusChanging: false
    })
  )
  .case(
    actions.changeDatabaseStatus.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseStatusChanging: false
    })
  )
  // Reset database password
  .case(
    actions.resetDatabasePassword.started,
    (state): Reducer => ({
      ...state,
      isDatabasePasswordResetting: true
    })
  )
  .case(
    actions.resetDatabasePassword.done,
    (state, payload): Reducer => ({
      ...state,
      databaseResetPassword: payload.result,
      isDatabasePasswordResetting: false
    })
  )
  .case(
    actions.resetDatabasePassword.failed,
    (state): Reducer => ({
      ...state,
      isDatabasePasswordResetting: false
    })
  )
  // Get database backup status
  .case(
    actions.getDatabaseBackupStatus.started,
    (state): Reducer => ({
      ...state,
      isDatabaseBackupStatusLoading: true
    })
  )
  .case(
    actions.getDatabaseBackupStatus.done,
    (state, payload): Reducer => ({
      ...state,
      databaseBackupStatus: payload.result,
      isDatabaseBackupStatusLoading: false
    })
  )
  .case(
    actions.getDatabaseBackupStatus.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseBackupStatusLoading: false
    })
  )
  // Get database backup files
  .case(
    actions.getDatabaseBackupFiles.started,
    (state): Reducer => ({
      ...state,
      areDatabaseBackupFilesLoading: true
    })
  )
  .case(
    actions.getDatabaseBackupFiles.done,
    (state, payload): Reducer => ({
      ...state,
      databaseBackupFiles: payload.result,
      areDatabaseBackupFilesLoading: false
    })
  )
  .case(
    actions.getDatabaseBackupFiles.failed,
    (state): Reducer => ({
      ...state,
      areDatabaseBackupFilesLoading: false
    })
  )
  // Restore database
  .case(
    actions.restoreDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseRestoring: true
    })
  )
  .case(
    actions.restoreDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseRestoring: false
    })
  )
  .case(
    actions.restoreDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseRestoring: false
    })
  )
  // Backup database
  .case(
    actions.backupDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseBackingUp: true
    })
  )
  .case(
    actions.backupDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseBackingUp: false
    })
  )
  .case(
    actions.backupDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseBackingUp: false
    })
  )
  // Cron backup
  .case(
    actions.cronBackup.started,
    (state): Reducer => ({
      ...state,
      isCronBackupUpdating: true
    })
  )
  .case(
    actions.cronBackup.done,
    (state): Reducer => ({
      ...state,
      isCronBackupUpdating: false
    })
  )
  .case(
    actions.cronBackup.failed,
    (state): Reducer => ({
      ...state,
      isCronBackupUpdating: false
    })
  )
  // Cron delete
  .case(
    actions.cronDelete.started,
    (state): Reducer => ({
      ...state,
      isCronBackupDeleting: true
    })
  )
  .case(
    actions.cronDelete.done,
    (state): Reducer => ({
      ...state,
      isCronBackupDeleting: false
    })
  )
  .case(
    actions.cronDelete.failed,
    (state): Reducer => ({
      ...state,
      isCronBackupDeleting: false
    })
  )
  // link database to S3
  .case(
    actions.s3LinkDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Linking: true
    })
  )
  .case(
    actions.s3LinkDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Linking: false
    })
  )
  .case(
    actions.s3LinkDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Linking: false
    })
  )
  // unlink database from S3
  .case(
    actions.s3UnlinkDatabase.started,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Unlinking: true
    })
  )
  .case(
    actions.s3UnlinkDatabase.done,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Unlinking: false
    })
  )
  .case(
    actions.s3UnlinkDatabase.failed,
    (state): Reducer => ({
      ...state,
      isDatabaseS3Unlinking: false
    })
  )
  // Create replica database
  .case(
    actions.createReplicaDatabase.started,
    (state): Reducer => ({
      ...state,
      isReplicaDatabaseCreating: true
    })
  )
  .case(
    actions.createReplicaDatabase.done,
    (state, payload): Reducer => ({
      ...state,
      replicaDatabase: payload.result,
      isReplicaDatabaseCreating: false
    })
  )
  .case(
    actions.createReplicaDatabase.failed,
    (state): Reducer => ({
      ...state,
      isReplicaDatabaseCreating: false
    })
  )
  // Get database replicas
  .case(
    actions.getReplicaDatabases.started,
    (state): Reducer => ({
      ...state,
      areReplicaDatabasesLoading: true
    })
  )
  .case(
    actions.getReplicaDatabases.done,
    (state, payload): Reducer => ({
      ...state,
      replicaDatabases: payload.result,
      areReplicaDatabasesLoading: false
    })
  )
  .case(
    actions.getReplicaDatabases.failed,
    (state): Reducer => ({
      ...state,
      areReplicaDatabasesLoading: false
    })
  )
  // clear store
  .case(actions.clear, (): Reducer => initialState)
  .case(
    actions.clearDatabase,
    (state): Reducer => ({
      ...state,
      database: null
    })
  )
  .case(
    actions.clearDatabaseResetPassword,
    (state): Reducer => ({
      ...state,
      databaseResetPassword: null
    })
  )
  .case(
    actions.clearReplicaDatabases,
    (state): Reducer => ({
      ...state,
      replicaDatabases: null
    })
  )
  .case(
    actions.clearBackupStatus,
    (state): Reducer => ({
      ...state,
      databaseBackupStatus: null
    })
  )
  .case(
    actions.clearBackupFiles,
    (state): Reducer => ({
      ...state,
      databaseBackupFiles: null
    })
  );
